@primary-color: #e8262d;
@link-color: #3498db;

.ant-modal-confirm-body {
    display: flex !important;
    align-items: center;

    .ant-modal-confirm-title {
        margin-left: 0.5rem !important;
    }
}

.ant-btn {
    border-radius: 0px;
    background-color: #FF6000;
    border: 0px !important;
    height: 40px;
    padding: 1px, 21px, 1px, 21px !important;
}

.ant-btn:hover {
    background-color: #FF6000;
}

.ant-tabs>.ant-tabs-nav {
    background-color: #fff;
    margin-bottom: 0;
}

.nav-space {
    .ant-tabs-nav {
        padding-left: 1rem !important;
    }
}

.nav-space-x {
    .ant-tabs-nav {
        margin: 0 0.75rem !important;
    }
}

.ant-card.ant-card-bordered {
    border-radius: 5px !important;
    border: none !important
}

.ant-card-body {
    padding: 20px !important;
}

.ant-card.custom-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.ant-menu-root.ant-menu-vertical {
    border-right: none !important
}

.ant-upload.ant-upload-drag .ant-upload {
    padding: 0 !important;
}

.ant-spin-nested-loading {
    height: 100%;
}

.ant-spin-container {
    height: 100%;
}

.ant-form-item-label>label {
    font-size: 11px !important;
    margin-top: 0.2rem !important;
    color: #666666;
    font-weight: 400;
}

.ant-form-item-control-input-content {
    height: 40px !important;
}

.ant-form-item-control-input-content .ant-input {
    font-size: 12px !important;
    border: 1px solid #dddddd !important;
    border-radius: 1px !important;
    // height: 40px !important;
}



.ant-input:focus {
    border-color: #f9471b !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0px #f9471b;
    box-shadow: 0 0 0 0px #f9471b;
}

.ant-input:hover {
    border-color: #f9471b !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0px #f9471b;
    box-shadow: 0 0 0 0px #f9471b;
}

// .ant-upload-list-text {
//     display: none !important;
// }

.antd-country-phone-input {
    .ant-select {
        min-width: 90px;
    }

    .ant-input-group-addon {
        background-color: inherit;

        .ant-select-focused {
            .ant-select-selector {
                color: inherit;
            }
        }

        .ant-select-selection-item {
            color: inherit;
        }
    }

    .ant-input {
        height: 40px !important;
    }
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border-color: #f5222d !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0px #f5222d;
    box-shadow: 0 0 0 0px #f5222d;
}

.ant-input {
    border: 0px !important;
    // height: 40px !important;
}

#control-ref_rePas,
#control-ref_password {
    border: 0px !important;
}



.ant-input-affix-wrapper {
    padding: 0px 11px 0px 11px !important;
    // height: 40px !important;
}

.ant-input-affix-wrapper-focused {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
    box-shadow: none;
}

.ant-input-password .ant-input {
    border: 0px !important;
    // height: 38px !important;
}

.ant-form-item-explain-error {
    font-size: 12px !important;
    color: #EA2A3F !important;
    font-weight: 400;
}


.ant-btn-primary:hover {
    background-color: #F36A16 !important;
}

.ant-btn-primary {
    background-color: #FF6000 !important;
    border: 0px !important;
    height: 40px;
    padding: 1px, 21px, 1px, 21px !important;
}

.ant-form-item-with-help .ant-form-item-explain {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
}
.categories-page {
    margin-top: 16px;

    &_left-section {
        ._cate-item {
            &:hover a {
                color: #f43314 !important;
            }

            &_selected {
                a {
                    color: #f43314 !important;
                }
            }
        }
    }

    &_right-section {
        .category-banner {
            overflow: hidden;
            position: relative;
            background: #ffff;
            height: 230px;

            .ant-carousel {
                width: 100%;
                height: inherit;

                .slick-dots {
                    width: inherit;
                }
            }

            .ant-image,
            .c-image {
                width: inherit;
                height: 230px;
                // top: 50%;
                // left: 50%;
                // transform: translate(-50%, -50%);
                object-fit: cover;
                background-size: cover;
                background-position: center;
            }
        }

        .product-badge {
            border-radius: 2px;
            border: 1px solid #ff6000;
            color: #ff6000;
            background: rgba(255, 255, 255, 0.7);
            writing-mode: vertical-lr;
            text-orientation: mixed;
            padding: 7.19px 1px 7.69px 1px;
        }

        ._cate-product-card {
            .ant-image {
                height: 100%;
                // margin: 10px auto;
                overflow: hidden;
                position: relative;

                img {
                    height: inherit;
                    max-width: 100%;
                    transition: all 0.5s;
                    -webkit-transform: scale(1);
                    transform: scale(1);
                }
            }

            &:hover img {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
            }

            &:hover a {
               color: #F43314 !important;
               text-decoration: none;
            }
        }

        .product-list {
            padding: 24px 16px 16px 16px;
            border-radius: 8px;
        }
    }
}

._addresses-box {
    padding: 20px 30px;


    .ant-radio-input:focus + .ant-radio-inner {
        /* box-shadow: 0 0 0 3px rgba(232, 38, 45, 0.12); */
        box-shadow: none;
    }
    
    
    ._create-address-form {
        .ant-form-item {
            .ant-form-item-label > label {
                color: #666;
                font-size: 12px !important;
                font-weight: 400;
            }
            .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
                display: inline-block;
                margin-left: 4px;
                color: #ff4d4f;
                font-size: 14px;
                font-family: SimSun, sans-serif;
                line-height: 1;
                content: "*";
                position: absolute;
                right: 10px;
            }
            .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
                margin-left: 12px;
            }
            .ant-input-lg {
                padding: 8px 12px;
                height: 40px;
            }

            .ant-input-lg,
            .ant-select-selector {
                border: 1px solid #ddd;
                font-size: 12px;
            }

            .ant-select-selector:hover {
                border: 1px solid #F9471B;
            }

            .ant-select-selection-placeholder {
                font-size: 12px;
            }
        }

        .ant-form-item:last-child {
            margin-bottom: 12px;
        }

        .ant-form-item:last-child + .ant-form-item {
            margin-bottom: 8px;
        }
    }
    ._cancel-btn {
        display: flex;
        height: 40px;
        padding: 4px 15px;
        align-items: flex-start;
        border: 1px solid #c4c6cf;
        background: #fff;
        line-height: 1.675;
        position: relative;
        display: inline-block;
        font-weight: 400;
        cursor: pointer;
        user-select: none;
        touch-action: manipulation;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        font-size: 14px;

        &:hover {
            background: #f6f6f6 !important;
        }
    }

    ._accept-btn {
        background-color: #f9471b !important;
        // height: 28px;
        // line-height: 20px;

        &:hover {
            background: #FF5D27 !important;
        }
    }

    .ant-radio-group-outline {
        display: flex;
        flex-direction: column;
        gap: 14px;

        .ant-radio-wrapper {
            span.ant-radio + * {
                padding-right: 12px;
                padding-left: 12px;
            }
        }
    }

    .ant-radio-wrapper:not(.ant-radio-group-outline > .ant-radio-wrapper) {
        margin-top: 14px;

        span.ant-radio + * {
            padding: 0 12px;
        }
    }
}

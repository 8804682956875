@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: Roboto, system-ui, sans-serif;
    }

    div {
        font-family: Roboto, system-ui, sans-serif;
    }

    p {
        font-family: Roboto, system-ui, sans-serif;
    }
}
@import '../node_modules/antd/lib/style/themes/default.less';
@import '../node_modules/antd/dist/antd.less';
@import './resources/less/antd.customize.less';
@import '../node_modules/react-quill/dist/quill.snow.css';

@import "./resources/font-awesome/all.css";
@import "./resources/less/common/variable";
@import "./resources/less/common/mixin";
@import "./resources/less/common/typography";

@import "./resources/less/page/categories.less";
@import "./resources/less/page/product-detail.less";

@import "~react-image-gallery/styles/css/image-gallery.css";
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

@import "./resources/less/page/address.less";
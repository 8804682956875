.product-detail-page {
    &_header {
    }
    &_body {
        .product-images {
            position: sticky;
            top: 0;

            .slick-slider {
                .slick-arrow {
                    background: #f7f7f7;
                    height: 100%;
                    width: 16px;

                    &:hover {
                        background:  #eeeeee;
                    }
                }
                .slick-next::before {
                    color: #5a5a5a;
                    content: "\f105";
                    font-family: "Font Awesome 6 Pro";
                    border-radius: 0 12px 12px 0;
                }

                .slick-prev::before {
                    color: #5a5a5a;
                    content: "\f104";
                    font-family: "Font Awesome 6 Pro";
                    border-radius: 12px 0 0 12px;
                }
            }

            .slick-slider:not(.mainSlider) {
                .slick-track {
                    display: flex;
                    flex-wrap: nowrap;
                }
            }
        }

        .product-info,
        .merchant-info {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .name {
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
            }

            .rate-preview {
                scale: 0.7;
                color: #ff7044;
            }

            .action-buttons {
                .ant-btn-primary:not(.ant-btn-primary[disabled]) {
                    border-radius: 4px !important;
                    background: linear-gradient(90deg, #ff7044 0%, #ff4000 100%);
                    border: 1px solid transparent;
                }

                .ant-btn-primary[disabled] {
                    border-radius: 4px !important;
                    color: #ffff;
                    // background-color: #B1B1B1 !important;
                    opacity: 0.3;
                }

                .ant-btn-default:not([disabled]) {
                    border-radius: 4px;
                    border: 1px solid #ff4000 !important;
                    color: #f9471b;
                    background: #ffff;
                }

                .ant-btn-default[disabled] {
                    opacity: 0.3;
                    border-radius: 4px;
                    border: 1px solid #ff4000 !important;
                    color: #f9471b;
                }

                .ant-btn-default:hover {
                    background: rgba(249, 71, 27, 0.1);
                }
            }

            .sku-input-number {
                width: 100%;
                display: flex;
                align-items: center;
                flex-wrap: nowrap;

                // button::after {
                    // display: none;
                // }

                [ant-click-animating-without-extra-node='true']::after {
                    z-index: 99
                }

                button:first-child {
                    border-radius: 5px 0 0 5px;
                    border-right: none;
                }
                button:last-child {
                    border-radius: 0 5px 5px 0;
                    border-left: none;
                }
                input {
                    border-right: none;
                    border-left: none;
                    border-radius: 0 !important;
                    border-top: 1px solid #ededed !important;
                    border-bottom: 1px solid #ededed !important;

                    &:hover {
                        border-color: #d9d9d9;
                    }

                    &:focus {
                        border-color: #d9d9d9;
                        // box-shadow: none;
                    }
                }
            }

            .variant-item {
                border-radius: 8px;
                // border: 1px solid transparent;
                border: 1px solid #ebebeb;
                padding: 6px 8px 6px 8px;
                cursor: pointer;
                font-size: 14px;
                font-weight: 400;

                .ant-badge-count {
                    right: 12px;

                    &::before {
                        content: "x";
                    }
                }

                &_selected {
                    border: 1px solid #f9471b;
                }

                &_disable-masked {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }

            ._btn-update-quantity {
                border: 1px solid #ededed !important;
                color: #1a1a1a !important;

                &:nth-child(1) {
                    border-right: none !important;
                    // &:focus {
                    //     box-shadow: none;
                    // }
                    // &::after {
                    //     box-shadow: none;
                    // }
                }
                &:last-child {
                    border-left: none !important;
                }
            }

            ._pin-bar {
                background: #ffff;
                border-radius: 8px 8px 0px 0px;
                padding: 12px 0;
            }
        }

        
    }

    ._merchant-search-input {
        // .ant-input {
            border-radius: 3px !important;
            border-top: 1px solid #ff4000 !important;
            border-bottom: 1px solid #ff4000 !important;
            border-left: 1px solid #ff4000 !important;
        // }
        
    }

    ._add-cart-success-modal {
        .ant-modal-content {
            border-radius: 12px !important;
        }
    }

    .product-info {
        position: relative; /* Đảm bảo thẻ con sẽ được đặt trong ngữ cảnh của thẻ cha */
        // height: 500px; /* Thiết lập chiều cao của thẻ cha */
        // overflow: auto; /* Cho phép cuộn nếu nội dung vượt qua chiều cao của thẻ cha */
    }

    ._pin-bar {
        position: sticky;
        bottom: 0;
        background-color: #f1f1f1;
        padding: 10px;
        transition: box-shadow 0.3s ease-in-out;
    }
}

.modal_succes_buttons {
    .ant-btn-primary:not(.ant-btn-primary[disabled]) {
        border-radius: 4px !important;
        background: linear-gradient(90deg, #ff7044 0%, #ff4000 100%);
        border: 1px solid transparent;
    }

    .ant-btn-primary[disabled] {
        border-radius: 4px !important;
        color: #ffff;
        // background-color: #B1B1B1 !important;
        opacity: 0.3;
    }

    .ant-btn-default {
        border-radius: 4px;
        border: 1px solid #ff4000 !important;
        color: #f9471b;
        background: #ffff;
    }

    .ant-btn-default[disabled] {
        opacity: 0.3;
    }
}

.product-description-tabs {
    .ant-tabs-nav {
        .ant-tabs-tab {
            padding: 18px 20px;
        }
    }
    
}